// import keycloak from '@/plugins/keycloak/keycloak'
import i18n from '@/plugins/i18n/i18n'
import moment from '@/plugins/moment/moment'
import infraComponents from '@/plugins/infra-components'
import vMoney from '@/plugins/v-money'

export default {
  ...infraComponents,
  // keycloak,
  i18n,
  moment,
  vMoney
}
