export default [
  {
    path: '/vouchers',
    name: 'aftersales-vouchers',
    component: () => import('@/views/VouchersList.vue'),
    meta: {
      title: 'Vouchers List',
      auth: true
    }
  }
]
