<template>
  <div>
    <v-app>
      <div class="after-sales">
        <router-view/>
      </div>
    </v-app>
  </div>
</template>
<script>
import router from './router'

export default {
  name: 'App',
  props: ['location'],
  data: () => ({
    newRoute: ''
  }),
  watch: {
    $route: function ({ path, fullPath, query }) {
      window.parent.postMessage({ path, fullPath, query }, '*')
    }
  },
  methods: {
    handleRouteChange (event) {
      router.push(event.detail.newRoute.replace('/', ''))
    }
  },
  created () {
    this.newRoute = this.location.includes('old-app')
      ? this.location.replace(
          `/old-app/${window.parent.IFC_VARIABLES.headers['x-tenant-id']}/aftersales`,
          ''
      )
      : this.$route.fullPath
  },
  mounted () {
    this.location.includes('old-app') && router.push(this.newRoute)
    window.addEventListener('changeRoute', this.handleRouteChange)

    // Make sure to remove the event listener when the component is destroyed
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('changeRoute', this.handleRouteChange)
    })
  }
}
</script>

<style lang="scss">
</style>
