import Vue from 'vue'
import i18n from '@/plugins/i18n/i18n'
import moment from 'moment'

const filters = {
  uppercase: (value) => {
    if (!value) return ''
    return value.toUpperCase()
  },
  cpf: (value) => {
    if (!value) return ''
    return value.replace(/([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/, '$1.$2.$3-$4')
  },
  money: (value) => {
    if (!value) return ''
    return i18n.n(value, 'currency')
  },
  date: (value) => {
    if (!value) return ''
    return value ? moment(value).format('DD.MM.YYYY/HH:mm') : '--'
  },
  typeVoucher: (value) => {
    if (!value) return ''

    const types = {
      voucher: i18n.t('voucher'),
      exchange_voucher: i18n.t('exchange_voucher')
    }

    return types[value.toLowerCase()]
  }
}

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
