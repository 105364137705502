import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@infralabs/infra-components-vue/dist/infra-components-vue.css'
import IfcComponents from '@infralabs/infra-components-vue'

const options = {}

Vue.use(Vuetify)
Vue.use(IfcComponents, options)

export default options
