import Vue from 'vue'
import VueRouter from 'vue-router'
// import keycloak from '@/plugins/keycloak/keycloak'
import routes from './routers'

Vue.use(VueRouter)

export const createRouter = (base = '') => {
  const router = new VueRouter({
    mode: 'hash',
    routes: [
      ...routes,
      {
        path: '*',
        redirect: {
          path: '/vouchers' // TODO this isn't the first route
        }
      }
    ]
  })

  return router
}

export default createRouter()
