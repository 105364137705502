import App from '@/App.vue'
import '@/filters'
import plugins from '@/plugins'
import router, { createRouter } from '@/router'
import store from '@/store'
import auth from '@/utils/app-shell-auth'
import getService from '@/utils/get-service'
import singleSpaVue from 'single-spa-vue'
import Vue from 'vue'

const postMessageTemp = window.parent.postMessage
window.parent.postMessage = function (message, targetOrigin, transfer) {
  postMessageTemp(JSON.parse(JSON.stringify(message)), targetOrigin, transfer)
}

Vue.config.productionTip = false

Vue.mixin({
  computed: {
    $auth: () => auth,
    $mfeUrl: () => getService().VUE_APP_MFE_URL
  }
})

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    router,
    store,
    ...plugins,
    render (h) {
      return h(App, {
        props: {
          tokenIdSubject: this.tokenIdSubject,
          location: this.location
        }
      })
    },
    el: '#mfe_div_connector'
  },
  handleInstance (app, props) {
    auth.listen(app.tokenIdSubject, app.$root.$emit, app.$root)
  }
})

export const bootstrap = props => {
  return Promise.resolve().then(() => {
    vueLifecycles.bootstrap(props)
  })
}

export const mount = props => {
  return Promise.resolve().then(() => {
    vueLifecycles.mount(props)
  })
}

export const unmount = props => {
  return Promise.resolve().then(() => {
    vueLifecycles.unmount(props)
  })
}

const options = { router, store, ...plugins }

export const withoutSingleSpa = ({ elementDOM, basePath, location, auth }) => {
  return new Vue({
    mixins: [
      {
        router: createRouter(basePath),
        mounted: function () {
          if (location) {
            router.push(location)
          }
        },
        ...options,
        computed: {
          $auth: () => auth
        }
      }
    ],
    render (h) {
      return h(App, {})
    }
  }).$mount(elementDOM)
}
